import React from "react";
import "./App.css";
import { TimeComponent, ScoreComponent } from "./DesktopSidePanel";

export const MobileSidePanel = ({
  isRedTurn,
  handleShare,
  colors,
  revealed,
  isSpymaster,
  gameOver,
  timer,
  formatTime,
  defaultTimer,
  setDefaultTimer,
  setTimer,
  handleEndTurn,
  gameMessage,
  setIsSpymaster,
  players,
  handleReset,
  windowSize,
  isBoardRevealed,
  setIsBoardRevealed,
  handleTimerToggle,
  pausedAt
}) => (
  <div className="side-panel-mobile">
    <div className="game-header">
      <div className="turn-indicator">
        <h2 className={isRedTurn ? "red" : "blue"}>
          {isRedTurn ? "Red" : "Blue"}'s Turn
          <svg
            className="copy-link"
            onClick={handleShare}
            viewBox="0 0 24 24"
            width="16"
            height="16"
            title="Copy game link"
          >
            <path
              fill="currentColor"
              d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
            />
          </svg>
        </h2>
        <button
          className={`end-turn-button ${isRedTurn ? "red" : "blue"}`}
          onClick={handleEndTurn}
        >
          End Turn
        </button>
      </div>

      <div className="score-row">
        <ScoreComponent
          colors={colors}
          revealed={revealed}
          scoreColor={"red"}
          windowSize={windowSize}
        />

        {!gameOver && (
          <TimeComponent
            defaultTimer={defaultTimer}
            timer={timer}
            formatTime={formatTime}
            setDefaultTimer={setDefaultTimer}
            setTimer={setTimer}
            handleTimerToggle={handleTimerToggle}
            pausedAt={pausedAt}
          />
        )}

        <ScoreComponent
          colors={colors}
          revealed={revealed}
          scoreColor={"blue"}
          windowSize={windowSize}
        />
      </div>
    </div>

    <div className="mobile-controls">
      {
        gameOver ? (
          <div className="toggle-buttons">
          <button
            className={`toggle-btn ${isBoardRevealed ? "active" : ""}`}
            onClick={() => setIsBoardRevealed(true)}
          >
            🔍 Reveal
          </button>
          <button
            className={`toggle-btn ${!isBoardRevealed ? "active" : ""}`}
            onClick={() => setIsBoardRevealed(false)}
          >
            🔍 Hide
          </button>
        </div>
        ) : (
          <div className="toggle-buttons">
          <button
            className={`toggle-btn ${!isSpymaster ? "active" : ""}`}
            onClick={() => setIsSpymaster(false)}
          >
            🎮 Player
          </button>
          <button
            className={`toggle-btn ${isSpymaster ? "active" : ""}`}
            onClick={() => setIsSpymaster(true)}
          >
            🕵️ Spymaster
          </button>
        </div>        )
      }

    </div>
    <button className="new-game-btn" onClick={handleReset}>
      🎲 New Game
    </button>

    {gameMessage && (
      <div className="message-content">
        {"🐧 "}
        {gameMessage}
      </div>
    )}
  </div>
);
