import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";
import { generatePlayerName, resetSpymasterStatus } from "./playerUtils";
import "./App.css";
import {
  getRandomMessage,
  generateId,
  generateImageUrls,
  generateColorKey,
  handleCardClickAnimation,
} from "./App.utils";
import { DesktopSidePanel, MadeWithLoveComponent } from "./DesktopSidePanel";
import { MobileSidePanel } from "./MobileSidePanel";

// Add window size hook
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

function App() {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [colors, setColors] = useState(Array(20).fill(null));
  const [revealed, setRevealed] = useState(Array(20).fill(false));
  const [firstTeamIsRed] = useState(Math.random() < 0.5);
  const [isRedTurn, setIsRedTurn] = useState(firstTeamIsRed);
  const [gameOver, setGameOver] = useState(false);
  const [gameMessage, setGameMessage] = useState("");
  const [isSpymaster, setIsSpymaster] = useState(false);
  const [recentMessages, setRecentMessages] = useState({});
  const [shareUrl, setShareUrl] = useState("");
  const [imageUrls, setImageUrls] = useState(Array(20).fill(null));
  const [players, setPlayers] = useState({});
  const [timer, setTimer] = useState(300); // 5 minutes = 300 seconds
  const [defaultTimer, setDefaultTimer] = useState(300); // Room default timer (in seconds)
  const [clicked, setClicked] = useState([]); // Track clicked cards, to prevent weird animations
  const [updatedAt, setUpdatedAt] = useState(null);
  const [pausedAt, setPausedAt] = useState(null); // Track when timer was paused
  const [totalPausedTime, setTotalPausedTime] = useState(0); // Track total time spent paused
  const [isBoardRevealed, setIsBoardRevealed] = useState(false);

  // Load player from localStorage or create new one
  const [currentPlayer, setCurrentPlayer] = useState(() => {
    const savedPlayer = localStorage.getItem("currentPlayer");
    if (savedPlayer) {
      return { ...JSON.parse(savedPlayer), active: true, isSpymaster: false};
    }
    const newPlayer = {
      name: generatePlayerName(),
      isSpymaster: false,
      active: true,
      playerId: generateId(),
    };
    localStorage.setItem("currentPlayer", JSON.stringify(newPlayer));
    return newPlayer;
  });

  useEffect(() => {
    const markPlayerInactive = () => {
      setCurrentPlayer((prev) => {
        return {
          ...prev,
          active: false,
        };
      });
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        markPlayerInactive();
      }
    };

    const handleBeforeUnload = (event) => {
      markPlayerInactive();
      event.preventDefault();
      event.returnValue = "";
    };

    // Handle desktop browser close/refresh
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Handle mobile browser tab close/switch
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Handle mobile app background/close
    window.addEventListener("pagehide", markPlayerInactive);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("pagehide", markPlayerInactive);
    };
  }, [currentPlayer, setCurrentPlayer]);

  const updateGameState = async (updates) => {
    try {
      const gameDoc = doc(collection(db, "games"), gameId);
      await setDoc(gameDoc, updates, { merge: true });
    } catch (error) {
      console.error("Error updating game state:", error);
      if (error.code === "resource-exhausted") {
        setGameMessage("Too many updates, please wait a moment...");
      }
    }
  };

  useEffect(() => {
    const updatePlayers = async () => {
      if (!gameId) return;

      if (
        JSON.stringify(players?.[currentPlayer.playerId]) !==
        JSON.stringify(currentPlayer)
      ) {
        await updateGameState({
          players: { ...players, [currentPlayer.playerId]: currentPlayer },
        });
      }
    };

    updatePlayers();
  }, [gameId, currentPlayer, players, updateGameState]);

  useEffect(() => {
    updateGameState({
      defaultTimer,
    });
  }, [defaultTimer]);

  useEffect(() => {
    setCurrentPlayer((prev) => {
      return {
        ...prev,
        isSpymaster: isSpymaster,
      };
    });
  }, [isSpymaster]);

  const createNewGame = async (player, allPlayers = {}) => {
    try {
      const newGameId = generateId();
      const isFirstTeamRed = Math.random() < 0.5;
      const colorKey = generateColorKey(isFirstTeamRed);
      const newImageUrls = generateImageUrls();

      const gameData = {
        colors: colorKey,
        revealed: Array(20).fill(false),
        isRedTurn: isFirstTeamRed,
        gameOver: false,
        gameMessage: `A chilly new game begins! ${isFirstTeamRed ? "Red" : "Blue"} takes the first waddle`,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        imageUrls: newImageUrls,
        players: resetSpymasterStatus(allPlayers),
      };

      const gameDoc = doc(collection(db, "games"), newGameId);
      await setDoc(gameDoc, gameData);
      return newGameId;
    } catch (error) {
      console.error("Error creating new game:", error);
      if (error.code === "resource-exhausted") {
        setGameMessage("Server is busy, please try again in a moment...");
        throw new Error("Server is busy");
      }
      throw error;
    }
  };

  const handleReset = async () => {
    try {
      setGameMessage("Creating new game...");
      // Create new game with all current players
      const newGameId = await createNewGame(currentPlayer, players);

      // Update current game with redirect info
      const gameDoc = doc(collection(db, "games"), gameId);
      await setDoc(
        gameDoc,
        {
          redirectToGame: newGameId,
          redirectedAt: new Date().toISOString(),
          gameOver: true,
          gameMessage: "Starting new game...",
        },
        { merge: true },
      );

      // Reset timer state for new game using the room's default timer and auto-start countdown
      setTimer(defaultTimer);
      setPausedAt(null);

      // Reset local state
      setClicked([]);
      setCurrentPlayer(null);
      setIsSpymaster(false);
      setGameOver(false);
      navigate(`/game/${newGameId}`);
    } catch (error) {
      console.error("Error resetting game:", error);
      if (error.message === "Server is busy") {
        return; // Message already set
      }
      setGameMessage("Failed to create new game. Please try again.");
    }
  };

  useEffect(() => {
    if (!gameId) {
      // Create new game
      createNewGame(currentPlayer).then((newGameId) => {
        navigate(`/game/${newGameId}`);
      });
    } else {
      const gameDoc = doc(collection(db, "games"), gameId);
      const unsubscribe = onSnapshot(gameDoc, (snapshot) => {
        const data = snapshot.data();
        if (!data) {
          console.log("No game data found");
          return;
        }

        // Check if game has been redirected
        if (data.redirectToGame) {
          navigate(`/game/${data.redirectToGame}`);
          return;
        }
        if (data.colors) setColors(data.colors);
        if (data.revealed) setRevealed(data.revealed);
        if ("isRedTurn" in data) setIsRedTurn(data.isRedTurn);
        if ("defaultTimer" in data) setDefaultTimer(data.defaultTimer);
        if ("updatedAt" in data) setUpdatedAt(data.updatedAt);
        if ("pausedAt" in data) setPausedAt(data.pausedAt);
        if ("totalPausedTime" in data) setTotalPausedTime(data.totalPausedTime);
        if ("gameOver" in data) setGameOver(data.gameOver);
        if (data.gameMessage) setGameMessage(data.gameMessage);
        if (data.imageUrls) setImageUrls(data.imageUrls);
        if (data.players) setPlayers(data.players);
      });

      // Set share URL
      setShareUrl(window.location.href);

      return unsubscribe;
    }
  }, [gameId, firstTeamIsRed, navigate, currentPlayer]);

  // Add helper to format the timer display
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  // Add new useEffect to count down the timer only when running
  useEffect(() => {
    let interval = null;
    if (!pausedAt && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            setPausedAt(new Date().toISOString());
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [pausedAt, timer]);

  // Reset timer when turn changes (always resets to M and immediately starts counting)
  useEffect(() => {
    if (!gameOver && updatedAt) {
      const now = new Date();
      const lastUpdate = new Date(updatedAt);
      const rawElapsed = Math.floor((now - lastUpdate) / 1000);
      // Subtract total paused time from elapsed time
      const elapsed = rawElapsed - totalPausedTime;
      const remainingTime = Math.max(0, defaultTimer - elapsed);
      setTimer(remainingTime);
    }
  }, [gameOver, updatedAt, defaultTimer, pausedAt, totalPausedTime]);

  const handleClick = (index) => {
    if (!colors || revealed[index] || isSpymaster) return;

    const newRevealed = [...revealed];
    newRevealed[index] = true;
    setRevealed(newRevealed); // Update immediately
    setClicked((prevClicked) => [...prevClicked, index]);

    if (colors[index] === "black") {
      updateGameState({
        revealed: newRevealed,
        gameOver: true,
        gameMessage: getRandomMessage(
          "assassin",
          recentMessages,
          setRecentMessages,
        ),
      });
    handleCardClickAnimation(index, setClicked);
    return;
    }

    if (colors[index] === "neutral") {
      updateGameState({
        revealed: newRevealed,
        isRedTurn: !isRedTurn,
        gameMessage: getRandomMessage(
          "neutral",
          recentMessages,
          setRecentMessages,
        ),
      });
    handleCardClickAnimation(index, setClicked);
    return;
    }

    const isCorrectTeam =
      (colors[index] === "red" && isRedTurn) ||
      (colors[index] === "blue" && !isRedTurn);
    const newIsRedTurn = isCorrectTeam ? isRedTurn : !isRedTurn;
    let message =  gameOver ? gameMessage : isCorrectTeam
      ? getRandomMessage("correct", recentMessages, setRecentMessages)
      : getRandomMessage("wrong", recentMessages, setRecentMessages);

    // Check win condition
    const remainingRed = colors.filter(
      (c, i) => c === "red" && !newRevealed[i],
    ).length;
    const remainingBlue = colors.filter(
      (c, i) => c === "blue" && !newRevealed[i],
    ).length;

    let newGameOver =  gameOver;
    if (!gameOver) {
      newGameOver = false;
      if (remainingRed === 0) {
        newGameOver = true;
        message = "Red wins! Mission accomplished!";
      } else if (remainingBlue === 0) {
        newGameOver = true;
        message = "Blue wins! Mission accomplished!";
      }
    }

    updateGameState({
      revealed: newRevealed,
      isRedTurn: newIsRedTurn,
      gameOver: newGameOver,
      gameMessage: message,
    });

    handleCardClickAnimation(index, setClicked);
  };

  const handleTimerToggle = () => {
    const now = new Date();

    setPausedAt((prev) => {
      let newPausedAt;
      if (prev === null) {
        newPausedAt = now.toISOString();
        // Pause timer
        updateGameState({
          pausedAt: newPausedAt,
          totalPausedTime: 0
        });
      } else {
        // Calculate additional pause time if we're resuming
        const additionalPauseTime = pausedAt ? Math.floor((now - new Date(pausedAt)) / 1000) : 0;
        const newTotalPausedTime = totalPausedTime + additionalPauseTime;
        newPausedAt = null;
        // Resume timer
        updateGameState({
          pausedAt: newPausedAt,
          totalPausedTime: newTotalPausedTime
        });
      }
      return newPausedAt;
    });

  };

  const handleEndTurn = () => {
    const newIsRedTurn = !isRedTurn;
    const newUpdatedAt = new Date().toISOString();
    // Optimistically update local turn state to trigger timer reset immediately
    setIsRedTurn(newIsRedTurn);
    setUpdatedAt(newUpdatedAt);
    updateGameState({
      colors,
      revealed,
      isRedTurn: newIsRedTurn,
      gameOver,
      updatedAt: newUpdatedAt,
      gameMessage: getRandomMessage(
        "endTurn",
        recentMessages,
        setRecentMessages,
      ),
    });
  };

  const handleShare = () => {
    navigator.clipboard.writeText(shareUrl);
    setGameMessage("Game link copied to clipboard!");
    setTimeout(() => {
      setGameMessage("");
    }, 2000);
  };

  return (
    <div className="App">
      <div className="game-board">
        <div className="game-container">
          <div className="grid-container">
            {colors.map((color, index) => (
              <div
                key={index}
                className={`grid-item ${isBoardRevealed ? "" : clicked.includes(index) ? "just-clicked" :  revealed[index] ? "revealed" : ""} ${isBoardRevealed || isSpymaster ? `spymaster-${color}` : ""}`}
                onClick={(e) => {
                  // Only handle click events on desktop
                  if (window.matchMedia('(min-width: 768px)').matches) {
                    handleClick(index);
                  }
                }}
                onTouchStart={(e) => {
                  const now = Date.now();
                  const lastTap = e.currentTarget.getAttribute('data-lasttap') || 0;
                  const tapLength = now - lastTap;

                  // Remove touch-hover class from all items first
                  document.querySelectorAll('.grid-item').forEach(item => {
                    item.classList.remove('touch-hover');
                  });

                  // Double tap detected (tap within 300ms)
                  if (tapLength < 300 && tapLength > 0) {
                    handleClick(index);
                    e.preventDefault();
                  } else {
                    // Only add hover effect if it's not a double tap
                    e.currentTarget.classList.add('touch-hover');
                  }

                  e.currentTarget.setAttribute('data-lasttap', now);
                }}
                onTouchMove={(e) => {
                  document.querySelectorAll('.grid-item').forEach(item => {
                    item.classList.remove('touch-hover');
                  });
                }}
                onTouchEnd={(e) => {
                  setTimeout(() => {
                    document.querySelectorAll('.grid-item').forEach(item => {
                      item.classList.remove('touch-hover');
                    });
                  }, 500);
                }}
              >
                <div className="card-inner">
                  <div className="card-face front">
                    <img
                      src={imageUrls[index]}
                      alt={`Card ${index}`}
                      className="card-image"
                    />
                  </div>
                  <div className="card-face back">
                    <img
                      src={`/${color}_2.png`}
                      alt={`${color} card`}
                      className="card-image"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {width >= 768 && <MadeWithLoveComponent />}
        </div>

        {width < 768 ? (
          <>
            <MobileSidePanel
              isRedTurn={isRedTurn}
              handleShare={handleShare}
              colors={colors}
              revealed={revealed}
              isSpymaster={isSpymaster}
              gameOver={gameOver}
              timer={timer}
              formatTime={formatTime}
              defaultTimer={defaultTimer}
              setDefaultTimer={setDefaultTimer}
              setTimer={setTimer}
              handleEndTurn={handleEndTurn}
              gameMessage={gameMessage}
              setIsSpymaster={setIsSpymaster}
              players={players}
              handleReset={handleReset}
              windowSize={width}
              isBoardRevealed={isBoardRevealed}
              setIsBoardRevealed={setIsBoardRevealed}
              handleTimerToggle={handleTimerToggle}
              pausedAt={pausedAt}
            />
            <MadeWithLoveComponent />
          </>
        ) : (
          <DesktopSidePanel
            isRedTurn={isRedTurn}
            handleShare={handleShare}
            colors={colors}
            revealed={revealed}
            isSpymaster={isSpymaster}
            gameOver={gameOver}
            timer={timer}
            formatTime={formatTime}
            defaultTimer={defaultTimer}
            setDefaultTimer={setDefaultTimer}
            setTimer={setTimer}
            handleEndTurn={handleEndTurn}
            gameMessage={gameMessage}
            setIsSpymaster={setIsSpymaster}
            players={players}
            handleReset={handleReset}
            windowSize={width}
            isBoardRevealed={isBoardRevealed}
            setIsBoardRevealed={setIsBoardRevealed}
            handleTimerToggle={handleTimerToggle} 
            pausedAt={pausedAt}
          />
        )}
      </div>
    </div>
  );
}

export default App;
