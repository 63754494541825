const adjectives = [
  "Icy",
  "Frosty",
  "Snowy",
  "Glacial",
  "Chilly",
  "Brisk",
  "Shivering",
  "Silent",
  "Brave",
  "Bold",
];
const nouns = [
  "Penguin",
  "Snowflake",
  "Iceberg",
  "Blizzard",
  "Aurora",
  "Glacier",
  "Tundra",
  "Waddle",
  "Huddle",
  "Drift",
];

export function generatePlayerName() {
  const adj = adjectives[Math.floor(Math.random() * adjectives.length)];
  const noun = nouns[Math.floor(Math.random() * nouns.length)];
  return `${adj} ${noun}`;
}

export function cleanupInactivePlayers(players) {
  const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000);
  return Object.fromEntries(
    Object.entries(players).filter(
      ([_, player]) => new Date(player.lastActive) > fiveMinutesAgo,
    ),
  );
}

export function resetSpymasterStatus(allPlayers) {
  return Object.entries(allPlayers).reduce((acc, [playerId, playerData]) => {
    acc[playerId] = {
      ...playerData,
      isSpymaster: false,
    };
    return acc;
  }, {});
}
