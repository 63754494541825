import React from "react";
import { Tooltip, Progress, Space, Flex } from "antd";
import "./App.css";

export const MadeWithLoveComponent = () => (
  <p style={{ marginTop: "20px", fontSize: "small", fontStyle: "italic" }}>
    {" "}
    Made with &nbsp;❤️&nbsp; by Swetha and Prafulla{" "}
  </p>
);

export const PlayerList = ({ players }) => {
  return (
    <Space size={4} wrap>
      <p style={{ fontSize: "small" }}>Players:</p>
      {Object.entries(players)
        .filter(([id, player]) => player?.active)
        .sort(([id1, a], [id2, b]) => a.name.localeCompare(b.name))
        .map(([playerId, player]) => (
          <p key={playerId} style={{ fontSize: "small", margin: "0" }}>
            {player?.isSpymaster ? "🕵️‍♀️" : "🎮"} {player.name}
          </p>
        ))}
    </Space>
  );
};

export const ScoreComponent = ({
  colors,
  revealed,
  scoreColor,
  windowSize,
}) => {
  const strokeColor = scoreColor === "red" ? "var(--red-color)" : "var(--blue-color)";
  const compact = windowSize < 500;
  return (
    <Progress
      type="circle"
      percent={Math.round(
        (colors.filter((c, i) => c === scoreColor && !revealed[i]).length /
          colors.filter((c) => c === scoreColor).length) *
          100,
      )}
      size={compact ? 40 : 60}
      strokeColor={strokeColor}
      format={() => {
        const remaining = colors.filter(
          (c, i) => c === scoreColor && !revealed[i],
        ).length;
        return remaining === 0 ? (
          "✓"
        ) : (
          <span
            style={{
              color: strokeColor,
              fontWeight: "bold",
              fontSize: compact ? "0.85rem" : "1rem",
            }}
          >
            {remaining}
          </span>
        );
      }}
    />
  );
};

export const TimeComponent = ({
  defaultTimer,
  timer,
  pausedAt,
  formatTime,
  setDefaultTimer,
  setTimer,
  handleTimerToggle,
}) => {
  return (
    <div className="timer-box">
      <span className="timer-display">{formatTime(timer)}</span>
      <span
        className="control-icon"
        onClick={() => {
          const newVal = Math.max(defaultTimer - 60, 60);
          setDefaultTimer(newVal);
          setTimer(newVal);
        }}
      >
        −
      </span>
      <span
        className="control-icon"
        onClick={handleTimerToggle}
      >
        {pausedAt ? "⏵︎" : "⏸︎"}
      </span>
      <span
        className="control-icon"
        onClick={() => {
          const newVal = defaultTimer + 60;
          setDefaultTimer(newVal);
          setTimer(newVal);
        }}
      >
        +
      </span>
    </div>
  );
};
export const DesktopSidePanel = ({
  isRedTurn,
  handleShare,
  colors,
  revealed,
  isSpymaster,
  gameOver,
  timer,
  formatTime,
  defaultTimer,
  setDefaultTimer,
  setTimer,
  handleEndTurn,
  gameMessage,
  setIsSpymaster,
  players,
  handleReset,
  windowSize,
  isBoardRevealed,
  setIsBoardRevealed,
  handleTimerToggle,
  pausedAt
}) => (
  <div className="side-panel">
    <div className="game-info">
      <Flex justify="space-between">
        <div className="team-progress">
          <ScoreComponent
            colors={colors}
            revealed={revealed}
            scoreColor={"red"}
            windowSize={windowSize}
          />
        </div>

        <h2 className={isRedTurn ? "red" : "blue"}>
          {isRedTurn ? "Red" : "Blue"}'s Turn
          <Tooltip title="Copy game link">
            <svg
              className="copy-link"
              onClick={handleShare}
              viewBox="0 0 24 24"
              width="16"
              height="16"
              title="Copy game link"
            >
              <path
                fill="currentColor"
                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
              />
            </svg>
          </Tooltip>
        </h2>
        <div className="team-progress">
          <ScoreComponent
            colors={colors}
            revealed={revealed}
            scoreColor={"blue"}
            windowSize={windowSize}
          />
        </div>
      </Flex>

      <div className="message-content">
        {"🐧 "}
        {gameMessage}
      </div>

      <button
        className={`end-turn-button ${isRedTurn ? "red" : "blue"}`}
        onClick={handleEndTurn}
        style={{ width: "100%" }}
      >
        End Turn
      </button>

      {!gameOver && (
        <TimeComponent
          defaultTimer={defaultTimer}
          timer={timer}
          formatTime={formatTime}
          setDefaultTimer={setDefaultTimer}
          setTimer={setTimer}
          handleTimerToggle={handleTimerToggle}
          pausedAt={pausedAt}
        />
      )}

        <div className="role-toggle">
          

            {
              gameOver ? (
                <div className="toggle-buttons">
                <button
                className={`toggle-btn ${isBoardRevealed ? "active" : ""}`}
                onClick={() => setIsBoardRevealed(true)}
              >
                🔍 Reveal
              </button>
              <button
                className={`toggle-btn ${!isBoardRevealed ? "active" : ""}`}
                onClick={() => setIsBoardRevealed(false)}
              >
                🔎 Hide
              </button>
              </div>

              ) : (
                <div className="toggle-buttons">
                <button
                className={`toggle-btn ${!isSpymaster ? "active" : ""}`}
                onClick={() => setIsSpymaster(false)}
              >
                🎮 Player
              </button>
              <button
                className={`toggle-btn ${isSpymaster ? "active" : ""}`}
                onClick={() => setIsSpymaster(true)}
              >
                🕵️ Spymaster
              </button>
              </div>
              )
            }

        </div>
      <button className="new-game-btn" onClick={handleReset}>
        🎲 New Game
      </button>
    </div>

    <PlayerList players={players} />
  </div>
);
