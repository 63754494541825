export function generateId() {
  return Math.random().toString(36).substring(2, 8);
}

export function generateImageUrls() {
  // Create array of numbers 1-283
  const allNumbers = Array.from({ length: 284 }, (_, i) => i + 1);

  // Shuffle array using Fisher-Yates algorithm
  for (let i = allNumbers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allNumbers[i], allNumbers[j]] = [allNumbers[j], allNumbers[i]];
  }

  // Take first 20 numbers and generate URLs
  return allNumbers.slice(0, 20).map((num) => `/clues/${num}.jpeg`);
}

export function generateColorKey(firstTeamIsRed) {
  const colors = [
    ...Array(firstTeamIsRed ? 8 : 7).fill("red"),
    ...Array(firstTeamIsRed ? 7 : 8).fill("blue"),
    ...Array(4).fill("neutral"),
    "black",
  ];

  for (let i = colors.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [colors[i], colors[j]] = [colors[j], colors[i]];
  }
  return colors;
}

export const getRandomMessage = (type, recentMessages, setRecentMessages) => {
  const messages = {
    correct: [
      "Nailed it! 🎯",
      "You're on fire! 🔥",
      "Mind reader alert! 🧠",
      "Spymaster is doing a happy dance! 💃",
      "That was pure genius! ⭐️",
      "Absolutely brilliant! 🌟",
      "You make it look easy! 💪",
      "Spot on! 🎪",
    ],
    wrong: [
      "Oops! Wrong team's card! 😅",
      "Plot twist! That wasn't yours... 🎭",
      "Someone needs new glasses! 👀",
      "Task failed successfully! 🙈",
      "Well, that was... unexpected! 😬",
      "Back to spy school! 📚",
      "Nice try, wrong card! 🎲",
      "Better luck next time! 🍀",
    ],
    neutral: [
      "Just a bystander passing by! 🚶‍♂️",
      "Nothing to see here... 👀",
      "Close, but no cigar! 🎯",
      "That was an innocent bystander! 🤷‍♂️",
      "Wrong person, right spirit! 😄",
      "Not the card you're looking for! 🔍",
      "Keep searching! 🗺️",
      "Almost had it! ⭐️",
    ],
    assassin: [
      "Game over! You found the assassin! 🕵️‍♂️",
      "Whoops! That was the assassin! 💀",
      "Mission failed spectacularly! 💥",
      "The assassin says hi! 👋",
      "That's gonna leave a mark... 🎭",
      "Time to call it a day! 🌙",
      "Game over, spies! 🎬",
      "The assassin strikes again! ⚡️",
    ],
    endTurn: [
      "Passing the baton! 🏃‍♂️",
      "Time for a coffee break! ☕️",
      "Your turn to shine! ✨",
      "Tag, you're it! 🎯",
      "Switching teams! 🔄",
      "Over to you! 👉",
      "Time to swap! 🔁",
      "Next team up! 📢",
    ],
    newGame: [
      "Fresh game, fresh mistakes! 🎲",
      "Let the mind games begin! 🧩",
      "Round and round we go! 🎠",
      "New game, who dis? 📱",
      "Time to show off those spy skills! 🕵️‍♂️",
      "Ready, set, spy! 🚦",
      "Game on! 🎮",
      "Let's crack some codes! 🔐",
    ],
  };

  // Get recently used messages for this type
  const recent = recentMessages[type] || [];

  // Filter out recently used messages
  const availableMessages = messages[type]?.filter(
    (msg) => !recent.includes(msg),
  );

  // If all messages have been used recently, reset the list
  const messagePool =
    availableMessages?.length > 0 ? availableMessages : messages[type];

  // Select a random message from available ones
  const randomIndex = Math.floor(Math.random() * (messagePool?.length || 0));
  const selectedMessage = messagePool[randomIndex];

  // Update recent messages for this type (keep last 3)
  const updatedRecent = [...recent, selectedMessage].slice(-3);
  setRecentMessages((prev) => ({
    ...prev,
    [type]: updatedRecent,
  }));

  return selectedMessage;
};

export const handleCardClickAnimation = (index, setClicked) => {

  const cleanupAnimation = (clickedIndex) => {
    setClicked(prev => prev.filter(i => i !== clickedIndex));
  };

  const card = document.querySelector(`.grid-item:nth-child(${index + 1})`);
  if (!card) return;

  // Calculate positions
  const rect = card.getBoundingClientRect();
  const cardCenterX = rect.left + rect.width / 2;
  const cardCenterY = rect.top + rect.height / 2;
  const viewportCenterX = window.innerWidth / 2;
  const viewportCenterY = window.innerHeight / 2;

  // Calculate required translation (no division here - handled in CSS)
  const translateX = viewportCenterX - cardCenterX;
  const translateY = viewportCenterY - cardCenterY;

  // Apply CSS custom properties
  card.style.setProperty("--card-translate-x", `${translateX}px`);
  card.style.setProperty("--card-translate-y", `${translateY}px`);
  // Trigger animation
  card.classList.add("reveal-animation");

  // Cleanup animation
  card.addEventListener(
    "animationend",
    () => {
      card.classList.remove("reveal-animation");
      cleanupAnimation(index);
    },
    { once: true },
  );
};
